import React from 'react'
import { Helmet } from 'react-helmet'
import FindBestPlanContent from 'componentsNew/FindBestPlan'
import LayoutNew from 'componentsNew/LayoutNew'

import { SettingsFormProvider } from 'componentsNew/FindBestPlan/settingsFormProvider'

const phone = '866-430-8512'

const FindBestPlanPage = ({ location }) => {
  return (
    <LayoutNew location={location} includeCitySearch>
      <Helmet>
        <title>Hands on Help to Navigate the Medicare Maze | Medicare Companion</title>
      </Helmet>
      <SettingsFormProvider>
        <FindBestPlanContent phone={phone} />
      </SettingsFormProvider>
    </LayoutNew>
  )
}

export default FindBestPlanPage
